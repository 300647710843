<template>
  <div class="hof-club">
    <item-title :title="{name: '名人汇', en_1: 'EVENTS', en_2: 'OF', en_3: 'ELITE', en_4: 'GROUP'}" />
    <div class="club-content">
      
      <div class="list">
        <div @click="toDetail(item)" v-for="(item, index) in listData" :key="index" class="list-item">
          <div class="bg"></div>
          <div class="pic">
            <img :src="item.pic" alt="">
          </div>
          <div class="detail">
            <div class="address">
              <span class="icon"></span>
              <span class="name">{{item.name}}</span>
            </div>
            <div class="time">{{item.disploy_at.substring(0, 10)}}</div>
          </div>
        </div>
      </div>
      <pager class="pager" :total="total" :page="params.page" :limit="params.limit" @current-change="handleCurrentChange"></pager>
    </div>
  </div>
</template>

<script>
import { getLifeAndFamousSnap } from '@/api/eliteStyle'
import ItemTitle from '@/components/common/itemTitle'
import Pager from '@/components/common/Pager.vue'
export default {
  components: {
    ItemTitle,
    Pager
  },
  data() {
    return {
      listData: [],
      params: {
        type: 4, 
        main_type: 2,
        page: 1,
        limit: 6
      },
      total: 0,
    }
  },
  created() {
    this.getData()
  },
  watch:{
    $route(to, from){
      if (from.name != 'eliteDetail' && to.name == 'club') {
        this.getData()
        // console.log(this.getNewsData)
      }      
    }
  },
  filters: {
    formatDate(dateTime) {
      return dateTime.substring(0, 10).split('-').join('.')
    }
  },
  methods: {
    // 跳转详情
    toDetail(item) {
      this.$emit('loading', true)
      this.$router.push({ path: '/eliteStyle/detail?type=club&id=' + item.id })
    },
    // 获取名人汇列表数据
    async getData() {
      let params = JSON.parse(JSON.stringify(this.params))
      const res = await getLifeAndFamousSnap(params)
      // console.log(1111,res)
      this.listData = res.records
      this.total = res.total
      this.$emit('loading', false)
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.$emit('loading', false)
      this.getData()
    },
  }
}
</script>

<style lang="scss" scoped>
.club-content {
  margin-top: 0.6rem;
  .tips {
    color: #cccccc;
    font-size: 0.16rem;
    text-align: right;
    margin-bottom: 0.2rem;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .list-item {
      width: 3.31rem;
      margin-right: 0.24rem;
      position: relative;
      padding-top: 0.07rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      .detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.25rem;
        .address {
          display: flex;
          align-items: center;
          font-size: 0.18rem;
          .icon {
            width: 0.12rem;
            height: 0.12rem;
            border-radius: 50%;
            border: 0.04rem solid #fe6917;
            margin-right: 0.1rem;
          }
          .name {
            flex: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }
        .time {
          color: #b8b7b7;
          font-size: 0.16rem;
        }
      }
      .bg {
        width: 3.19rem;
        height: 1.78rem;
        background: #ffffff;
        box-shadow: 0px 0.02rem 0.1rem 0rem #e8ecf1;
        border-radius: 0.06rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        z-index: -1;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      .pic {
        background-color: #ffffff;
        width: 100%;
        height: 1.9rem;
        border-radius: 0.06rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .club-content {
    .list {
      .list-item {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        .pic {
          img {
            height: auto;
          }
        }
      }
    }
  }
}
</style>